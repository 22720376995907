html,
body {
  margin: 0;
  overflow: hidden;
}

.App {
  width: 100vw;
  height: 100vh;
}
